<template>
  <CCard class="table-header" slot="header">
    <CCardBody class="table-header__content">
      <div class="table-header__control">
        <CInputCheckbox
          label=" "
          :checked="isSelectedAll"
          @update:checked="() => $emit('choseAll')"
        />

        <AppSlect
          class="table-header__select"
          :value="selectType"
          :options="statusOptions"
          @input="value => $emit('update:selectType', value)"
        />

        <CDropdown
          v-if="isSelect"
          :show.sync="showActionsMenu"
          color="primary"
          toggler-text="Actions"
          class="table-header__actions"
        >
          <slot name="action"/>
        </CDropdown>
      </div>

      <slot name="btn"/>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "table-header",

  props: {
    isSelect: {
      type: Boolean,
      default: false
    },

    isSelectedAll: {
      type: Boolean,
      default: false
    },

    selectType: {
      type: String,
      default: "all"
    }
  },

  data: () => ({
    showActionsMenu: false,

    statusOptions: [
      { value: "all", label: "Select all" },
      { value: "matching", label: "Select all matching" }
    ]
  })
};
</script>

<style lang="scss" scoped>
.table-header {
  width: 100%;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 575.98px) {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &__control {
    display: flex;
    align-items: center;
    min-height: 33px;

    @media (max-width: 575.98px) {
      width: 100%;

      margin-bottom: 1rem;

      justify-content: space-between;
    }
  }

  &__actions {
    margin-left: 1.5rem;
  }

  &__select {
    margin-bottom: 0;
  }
}
</style>