<template>
  <div class="multiselect-wraper">
    <p v-if="label" class="multiselect-wraper__label">{{label}}</p>

    <el-select
      v-model="localValue"
      :label="localValue"
      multiple
      filterable
      remote
      reserve-keyword
      placeholder="Please enter a keyword"
      :remote-method="remoteMethod"
      :loading="loading"
      ref="el"
      @input="handlerInput"
    >
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
  </div>
</template>

<script>
import { axios, showNotification } from "../../utils/axios";

import MixinVModel from "@/mixins/MixinVModel";

import $ from "jquery";

export default {
  name: "Multiselect",

  mixins: [MixinVModel],

  components: {},

  props: {
    data: {
      type: Array,
      default: () => []
    },

    label: {
      type: String,
      default: null
    },

    placeholder: {
      type: String,
      default: null
    },

    apiName: {
      type: String,
      default: null
    }
  },

  data: () => {
    return {
      // options: [],
      value: [],
      list: [],
      loading: false,
      states: []
    };
  },

  created() {
    // if (!this.apiName) this.states = this.data;

    if (!this.apiName) this.list = this.data;

    if (this.data && typeof this.data[0] !== "object")
      this.list = this.data.map(item => {
        return { value: `${item}`, label: `${item}` };
      });

    this.options = this.list;

    if (this.apiName == "numbers" && this.$route.query.phone_number_label)
      this.search(this.$route.query.phone_number_label);
    // this.localValue = [95];
  },

  methods: {
    handlerInput() {
      // $(this.$refs.el).val("");
      // console.log(
      //   ($(this.$refs.el.$el)
      //     .find(".el-select__input")
      //     .get(0).value = "hello")
      // );
      // console.log("fff", $(this.$refs.el));
      // console.log("DDd");
    },

    remoteMethod(query) {
      // console.log(query);

      if (query) {
        if (this.apiName) this.search(query);
      } else {
        this.options = this.list;
      }
    },

    search(query) {
      let apiName = this.apiName == "emails" ? "users" : this.apiName;

      this.loading = true;

      axios
        .get(`/admin_api/lw/${apiName}/?search=${query}`, {
          params: {}
        })
        .then(response => {
          this.loading = false;

          if (this.apiName == "emails") {
            let options = [];

            for (let item of response.data) {
              options.push({
                value: item.label,
                label: item.label
              });
            }

            this.options = options;
          } else this.options = response.data;

          // this.localValue = [95];

          console.log(response.data);
        })
        .catch(error => {
          console.log(error);

          showNotification({
            type: "danger",
            text: `${error}`
          });
        });
    }
  }
};
</script>



<style lang="scss">
.multiselect-wraper {
  margin: 0.5rem 0;

  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: 0.5rem;
  }
}
</style>

